import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  Text,
  Container,
  Image,
  Box,
  Button,
  HStack,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import CallForRes from "./modal/CallForRes";
import {
  useHeaderMultipleDisclosure,
  useKsProfileMultipleDisclosure,
} from "./hook/useMultipleDisclosure";
import kongsamMap from "../img/kongsamMap.png";
import kongsamUserChk from "../img/kongsamUserChk.png";
import kongsamUserPass from "../img/kongsamUserPass.png";
import kongsamRes01 from "../img/kongsamRes01.png";
import kongsamRes02 from "../img/kongsamRes02.png";
import MyUserConfirmationModal from "./modal/MyUserConfirmationModal";
import useUser from "../lib/useUser";
import { KONGSAM_INFO_PHONE } from "../lib/constants";

const Map = () => {
  return <Image src={kongsamMap} alt="kongsam map" w="100%" />;
};
const UserChk = () => {
  return (
    <HStack>
      <Image src={kongsamUserChk} alt="kongsam user chk" w="50%" />
      <Image src={kongsamUserPass} alt="kongsam user pass" w="50%" />
    </HStack>
  );
};
const UserRes = () => {
  return (
    <>
      <Image src={kongsamRes01} alt="kongsam res1" w="40%" />
      <Image src={kongsamRes02} alt="kongsam res2" w="100%" />
    </>
  );
};
export default function Help() {
  const { isLoggedIn } = useUser();
  const disclosures = useHeaderMultipleDisclosure();
  const disclosures_user = useKsProfileMultipleDisclosure();
  return (
    <Flex mt={10} align={"center"} justify={"center"}>
      <Container maxW="2xl">
        <Accordion allowMultiple width="100%" maxW="2xl" rounded="2xl">
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}
            >
              <Text fontSize="md" fontWeight={700} color={"gray.700"}>
                비회원 예약문의
              </Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Box fontSize={"sm"}>
                <Text color="gray.500">콩삼 방문이 처음인 회원님께서는</Text>
                <Button
                  py={3}
                  colorScheme="teal"
                  variant="link"
                  onClick={() => disclosures.CallRes.onOpen()}
                >
                  <Text color="green.600">예약하러가기 &rarr;</Text>
                </Button>
                <Text color="gray.500">
                  작성해 주시면 확인 후 연락드리겠습니다.
                </Text>
                <Text color="gray.500" mt={2}>
                  <span>미용 중에는 연락을 받지 못할 수 있습니다.</span>
                  <br />
                  연락처 : {KONGSAM_INFO_PHONE}
                </Text>
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}
            >
              <Text fontSize="md" fontWeight={700} color={"gray.700"}>
                콩삼회원 로그인 / 비밀번호 재설정
              </Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Box fontSize={"sm"}>
                <Text color="gray.500">
                  <span>별도의 가입은 없고, </span>
                  <br />
                  <span>2023년부터 서비스를 1회라도 이용하셨다면,</span>
                  <br />
                  <span>
                    콩삼에 문자나, 연락을 주시면 사용자 아이디 발급해 드리고,
                    "약간의 시간 걸림"
                  </span>
                  <br />
                  <span>'회원확인 &rarr;' 클릭</span>
                  <br />
                  <span>
                    발급된 아이디, 서비스받은 반려견 이름, 본인 연락처로 확인할
                    수 있고,
                  </span>
                  <br />
                  <span>
                    본인 확인되었다면 비밀번호 설정 후 로그인할 수 있습니다.
                  </span>
                  <br />
                  <span>
                    다소 복잡한 이유는 본인 확인을 하려는 절차이기 때문에 양해
                    부탁드립니다.
                  </span>
                </Text>
                <Text color="gray.500" mt={2}>
                  <span>미용 중에는 연락을 받지 못할 수 있습니다.</span>
                  <br />
                  연락처 : {KONGSAM_INFO_PHONE}
                </Text>
                {!isLoggedIn ? (
                  <>
                    <Button
                      py={3}
                      colorScheme="teal"
                      variant="link"
                      onClick={() =>
                        disclosures_user.MyUserConfirmationModal.onOpen()
                      }
                    >
                      <Text color="green.600">회원 확인 &rarr;</Text>
                    </Button>
                    <UserChk />
                  </>
                ) : (
                  <Text
                    fontSize={"md"}
                    fontWeight={700}
                    color="green.600"
                    mt={3}
                  >
                    로그인되어 있습니다.
                  </Text>
                )}
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}
            >
              <Text fontSize="md" fontWeight={700} color={"gray.700"}>
                콩삼회원 예약
              </Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Box fontSize={"sm"}>
                <Text color="gray.500">1. 로그인</Text>
                <Text color="gray.500">
                  2. 우측 상단 아이콘 클릭 후 프로필 선택
                </Text>
                <Text color="gray.500">
                  3. 등록된 반려견 확인 후 미용예약 버튼 선택
                </Text>
                <Text color="gray.500">4. 미용 예약 등록</Text>
                <Text color="red.300">
                  * 예약 시간이 확정은 아닙니다. <br /> * 콩삼에서 예약 확인 후
                  연락을 드리고, 시간 조정 및 금액, 반려견 상태 확인을 합니다.
                </Text>
                <UserRes />
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}
            >
              <Text fontSize="md" fontWeight={700} color={"gray.700"}>
                콩삼위치
              </Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Box fontSize={"sm"}>
                <Map />
                <Text color="gray.500" mt={3}>
                  주소 : 전북특별자치도 전주시 완산구 팔달로 130 1층, 콩삼
                </Text>
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        {disclosures.CallRes.isOpen && (
          <CallForRes
            isOpen={disclosures.CallRes.isOpen}
            onClose={disclosures.CallRes.onClose}
          />
        )}
        {disclosures_user.MyUserConfirmationModal.isOpen && (
          <MyUserConfirmationModal
            isOpen={disclosures_user.MyUserConfirmationModal.isOpen}
            onClose={disclosures_user.MyUserConfirmationModal.onClose}
          />
        )}
      </Container>
    </Flex>
  );
}
